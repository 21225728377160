

.detail-doc {
    width: calc(100% - 400px);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    & .button-return {
        width: 30px ; 
        height: 30px ; 
        @include buttonStyle("icons") ; 
        background-color: $color_2;
        border: 1.25px solid darken($color_2, 10) ;
        position: absolute;
        left: 10px;
        top: 10px;

        & .tc-icons {
            width: 100%;
            height: 100%;
            
            & svg {
                width: 50%;
                height: 50%;
            }
        }

        & a {
            width: 100%;
            height: 100%;


        }

        &:hover {
            background-color: darken($color_2, 10) ;
        }
    }


    &--prefixes {
        width: 100%;
        height: 100%;
        //background-color: rgb(189, 252, 252);
        display: flex;
        flex-direction: column;
        align-items: center;
        
        & .header {
            width: calc(100% - 40px);
            height: 50px;
            border-bottom: 1px solid rgba(102,102,102,.532);
            display: flex;
            align-items: center;
            justify-content: flex-end;


            & button{
                @include buttonStyle() ; 
                border : 1.5px solid darken(#e63946, 10) ; 
                background-color: #e63946;
                color: white;
                cursor: pointer;
                transition : all 100ms ; 

                &:hover {
                    transform: scale(1.05);
                }
            }
        }

        & .container {
            width: 100%;
            height: calc(100% - 50px);
            display: flex;
            flex-wrap: wrap;
            gap: 20px; // Utilisation de 'gap' pour un espacement uniforme
            overflow-y: auto; // Change à 'auto'
            overflow-x: hidden;

            & > div {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                align-content: start ; 
                & > div {
                    width: calc(50% - 2px - 20px); // Juste un exemple
                    min-width: 200px;
                    max-width: 400px;
                    background-color: #FEFEFE;
                    margin: 10px;
                    border-radius: 5px;
                    border: 1px solid #E0E0E0;
                    min-height: 100px;
                    max-height: 200px;
                    box-shadow: 0px 7px 9px -2px rgba(0, 0, 0, 0.11);
                    overflow: hidden;
                    cursor: pointer;

                    transition : all 100ms ; 

                    &:hover {
                        transform: scale(1.03);
                    }
    
                    & h1 {
                        width: calc(100%);
                        height: calc(40px - 10px);
                        background: $color_1;
                        color: white;
                        padding: 5px 0px;
                        margin: 0;
                        font-weight: 600;
                        font-size: 15px;
                        text-align: center;
                        justify-content: center;
                        display: flex;
                        align-items: center;
                    }
    
                    & > div {
                        width: calc(100% - 20px);
                        
                        padding: 20px 10px;
                        display: flex;
                        flex-direction: column;
                        overflow-y: scroll;
                        cursor: pointer;
    
                        & p {
                            width: 100%;
                            margin: 5px 0px;  
                            color: $color_1;  
                            display: flex;
                            justify-content: space-between;
                            font-size: 15px;
    
                            & span {
                                &:first-child {
                                    text-decoration: underline;
                                    margin-right: 5px;
                                    opacity: 0.8;
                                }
    
                                &:last-child {
                                    font-weight: bold;
                                    letter-spacing: 0.5px;
                                }
                            }
                        }
                    }
                }
            }


        }
    }

    // IN THE SAME GRID OF GENERAL INFOS
    &--generalInfos {
        width: calc(400px);
        height: calc(100% - 40px);
        border-left: 1.5px solid rgba(102, 102, 102, 0.223);
        //background-color: rgb(212, 189, 252);
        display: flex;
        flex-direction: column;
        align-items: center;

        & > h1 {
            color: whitesmoke;
            letter-spacing: 0.5px;
            animation: flash 3s infinite;

            @keyframes flash {
                0% {
                    opacity: 1;
                }
                50% {
                    opacity: 0.5;
                }
                100% {
                    opacity: 1;
                }
            }
        }

        & .doughnut {
            width : 50% ;
            padding: 20px ;
            
            & canvas {
                padding: 10px;
                overflow: visible!important;
            }
        }

        & .summary {
            width: calc(100% - 10px);
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            padding-left: 10px;

            &--element {
                width: calc(50% - 20px);
                height: 60px;
                margin: 10px;
                display: flex;

                & > div {
                    display: flex;
                    height: 100%;
                    &:first-child {
                        width: calc(20px - 10px);
                        margin-right: 10px;
                        height: 100%;
                        border-radius: 3px;
                    }

                    &:last-child {
                        display: flex;
                        flex-direction: column;
                        
                        & h2 {
                            height: 20px;
                            color: whitesmoke;
                            font-size: 14px;
                            margin: 0;
                            text-transform: uppercase;
                            font-weight: 500;
                            opacity: 0.8;
                        }
                        & p {
                            height: 40px;
                            width: 100%;
                            font-size: 25px;
                            margin: 0;
                            color : white ; 
                            display: flex;
                            align-items: center;
                        }
                    }
                }

                & .color {
                    &-total {
                        background-color: #F4A261;
                    }
                    &-valid {
                        background-color: #2A9D8F;
                    }
                    &-invalid {
                        background-color: #E63946;
                    }
                }


            }
        }
    }
}