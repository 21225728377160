.invalid-doc {
    & .content {
        padding: 0px;
        width: 100%;
        height: 100%;
    }

    & .basepopup__container {
        width: calc(100% - 100px);
        height: calc(100% - 100px);
        max-width: 900px;
        max-height: inherit;
    }

    .tc-table {
        &__thead {
            background-color: rgb(230, 57, 70);
        }

        &__footer {
            background-color: rgb(230, 57, 70);
        }
    }

    &--header {
        width: calc(100% - 20px);
        height: calc(100% - 10px);
        padding: 5px 10px;
        border-bottom: 1px solid rgba(102,102,102,.532);
        display: flex;
        align-items: center;

        & button {
            @include buttonStyle()
        }
    }

    & .mini-load svg {
        width: 40px;

        & path {
            fill : $color_1!important
        }
    }

    

}