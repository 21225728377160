html, body, #root {
    width: 100%;
    height: 100%;
    display: flex;
    font-family: $font!important;
    background-color: $color_1 ;
    overflow: hidden;
    user-select: none;
    margin: 0;
}


.separator {
    width: 2px;
    background-color: white;
    background: rgb(245,245,245);
    background: linear-gradient(0deg, rgba(245,245,245,1) 0%, rgba(245,245,245,0) 50%, rgba(245,245,245,1) 100%);
}

.tc-datepicker__day--selected {
    background-color: $color_2;
}

.tc-datepicker__day--keyboard-selected {
    background-color: rgba(1, 180, 1, 0.235)!important;
}

.tc-icons {
    display: flex;
    justify-content: center;
    align-items: center;
}

h1 {
    font-size: 18px;
}

p {
    font-size: 14px;
}

.app {
    width : calc(100% - 70px) ; 
    height: calc(100% - 50px);
    margin-left: 70px;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 1;
        height: 100%;
        background-color: rgb(245, 245, 245);
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        overflow: hidden;
    }

}


.underconstruction {
    width: calc(100% - 50px);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-left: 50px;
    & svg {
        height: 50%;
    }

    & p { 
        font-size: 22px;
        font-weight: bold;
    }
}


.tc-table {

    &__header {

        & .return svg path {
            fill : red!important
        }

        & .save svg path {
            fill : rgb(1, 101, 177)!important
        }

        & .editing-active svg path {
            fill : #bd7e00!important
        }
    }
    &__thead {
        background: $color_1!important;
    }

    &__tbody {

        & input,div, p {
            font-family: $font;
            font-size: 13px;
            color: $color_1;
        }

        & .events {
            &-select {
                background-color: #760ac831;
            }
        }
        
        &--row{
            transition : all 20ms ; 
            font-size: 13px;
            &:hover {
                background-color: #a650e71e;
            }

            &--column {
                transition : all 20ms ; 
            }
        }

        & .editing {

            & > input {
                font-family: $font;
                font-size: 13px;

                &:focus {
                    background-color: #48aa2a34;
                }
            }
        }

        & .description {

            &--input {
                width: 100%;
                height: 100%;
                display : flex; 
                justify-content: flex-end;
                &--linked {
                    width: 100%;
                    height: 100%;
                    & input {
                        width: calc(100% - 30px)!important;
                        padding-left: 30px!important;
                    }
                }
            }
            &--linked {
                width: calc(100% - 30px - 10px);
                padding-left: 30px;
                padding-right: 10px;
            }
        }

        & .unit, .total_ht {
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;

            &--input input {
                text-align: center;
            }

            &:hover {
                background-color: rgba(128, 128, 128, 0.271);
                cursor : not-allowed;
            }
        }

        & .total_ht {
            display: flex;
            width: calc(100% - 20px);
            height: 100%;
            padding: 0px 10px;
            justify-content: flex-end;
            align-items: center;
            font-weight : bold ; 

            &:hover {
                background-color: rgba(128, 128, 128, 0.271);
                cursor : not-allowed;
            }
        }

        & .pricing {
            width: calc(100% - 20px);
            padding: 0px 10px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            text-align: end;
            &--input {
                display: flex;
                & input {
                    text-align: end;
                    
                }

                & div {
                    display: flex;
                    align-items: center;
                    margin-right: 10px;
                }
            }
        }

        & .quantity--input input {
            text-align: center;
        }

        & .input {
            &--quantity {
                text-align: center;
            }
        }
    }

    &__footer {
        background: $color_1!important;
    }
}

.totals-view {
    position: absolute;
    bottom: 0;
    left: 0px;
    height: 30px;
    display: flex;
    align-items: center;
    z-index: 2000;
    width: 180px;
    padding : 0px 10px ; 

    & p {
        width: 100%;
        height: 100%;
        //background-color: $color_2;
        color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: default;

    }

    & > p {
        height: calc(100% - 2px);
        margin: 0;
        font-weight: bold !important;
        display: flex;
        justify-content: space-between;
        align-items: center; 
        z-index: 2000;
    }

    &--detail {
        width: calc(100%);
        position: absolute;
        background-color: $color_2;
        //bottom: calc(100% - 2px);
        left: 0px;
        z-index: 1000;

        & p {
            width: calc(100% - 20px);

            padding : 0px 10px ; 
            opacity: 0.9;
            height: 40px;
            margin: 0;


            &:hover {
                background-color : $color_1 ; 
            }

        }
        
    }
}

.dropdown {
    @include dropdownStyle() ; 
}