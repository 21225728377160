
//COMPONENTS : AUTHENTIFICATION + LOGIN (0)
.sign-in {
    width: 100%;
    height: 100%;
    display: flex;
    //background-color: $background__app;
    background-color: $color_1; 

    & .mini-load svg  {
        width: 40px;
    }
    
    & .form {
        width: 35%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        & .logo {
            padding: 20px 0px;
            height: 40px;
            //margin-bottom: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            & svg {
                height: 100%;
            }
        }

        & .content {
            width: 80%;
            display: flex;
            flex-direction: column;
            align-items: center;

            & > div {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 90%;
                margin: 15px 0px;

                & label {
                    width: 100%;
                    margin: 10px 0px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 17px;
                    font-weight: bold;
                    color : white; 

                }

                & input {
                    @include inputStyle()
                }

            }

            & > a {
                width: 100%;
                display: flex;
                justify-content: center;
                font-style: italic;
                text-decoration: underline;
                color : white; 
                cursor: pointer;
                font-weight: 500;
                text-align: center;
                font-size: 13px;
                margin-top: 10px;
                letter-spacing: 0.5px;
                opacity: 0.7;

                &:hover {
                    opacity : 1
                }
            }

            & .main-button {
                width: 100px;
            }


        }

        & iframe {
            transform: scale(0.9);
        }
    }

    

    & .illustration{
        width: 65%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & svg {
            width: 50%;
        }
    }
}