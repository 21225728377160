

.selected-prefix {

    & .content {
        padding: 0px;
        width: 100%;
        height: calc(100% - 30px);
    }

    & .basepopup__container {
        width: calc(100% - 100px);
        height: calc(100% - 100px);
        max-width: 900px;
        max-height: inherit;
    }

    &--header {
        width: calc(100% - 20px);
        height: calc(100% - 10px);
        padding: 5px 10px;
        border-bottom: 1px solid rgba(102,102,102,.532);
        display: flex;
        align-items: center;

        & button {
            @include buttonStyle()
        }
    }

    & .mini-load svg {
        width: 40px;

        & path {
            fill : $color_1!important
        }
    }

    

}