.navigation {
    width: 60px;
    padding-left: 5px;
    background-color: $color_1 ; 
    display: flex;
    position: fixed;
    left: 0;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition : width 150ms ; 
    overflow: hidden;
    user-select: none;
    z-index: 100;


    & .logo {
        width: 100%;
        height: 50px;
        display: flex;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        & > div {

            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            transition : all 330ms ; 
    
        }

        & svg {
            width: 65%;
        }
    }

    &__container {
        display: flex;
        flex: 1 1;
        width: 100%;
        flex-direction: column;

        & .download {
            cursor: pointer;

            & p {
                text-align: justify;
            }
            & svg {
                width: 20px;
                padding: 2.5px;
            }
        }

        & > div {
            width: 100%;
            height: 40px;
            margin : 10px 0px ;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;


            & .active {
                background: $color_2;
                border-radius: 10px;
                & svg path {
                    fill : $color_1!important ; 
                }

                & p {
                    color: $color_1!important;
                }
            }
            & > a, button {
                width: 80%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                background-color: transparent;
                border: none;

                & div {
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    & svg {
                        width: 20px;
                        margin-bottom: 2px;

                        & path {
                            fill : $color_1
                        }
                    }
                }
                & p {
                    width: 0;
                    color: white!important ; 
                    font-size: 13px;
                    overflow: hidden;
                    font-weight: 400;
                    opacity: 0;



                }
            }
        }
    }

    & .logout{
        width: 100%;

        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;

        & div {
            padding : 0px 10px ; 

            & svg {
                width: 20px;
                & path {
                    fill : rgb(148, 1, 1) ; 
                }
            }
        }

        & p {
            width: 0;
            overflow: hidden;
            font-weight: 700;
            color : rgb(148, 1, 1) ; 
            cursor: pointer;
            opacity: 0;
            font-size: 13px;

        }


    }

    &:hover {
        width: 230px;
        box-shadow: 3px 1px 12px 0px #1414141a;
        z-index: 3000;


        & .logo {
            margin-top: 10px;

            &  svg {
                width: 50%;
            }
        }
        


        & .navigation__container {

            & > div {
                & > a, button {
                    overflow: hidden;
                    padding-left: 10px;
                    justify-content: space-between;

                    & p {
                        width: 100px;
                        height: 100%;
                        opacity: 1;
                        flex: 1 1;
                        padding-left : 10px ; 
                        position : relative ; 
                        display: flex;
                        align-items: center;
                        justify-content: center;


                        & span {
                            position: absolute;
                            width: 200px;
                            left: 10px;
                        }
                    }
                }
            }
        }

        & .logout{
            width: 100%;
            
            height: 50px;
            display: flex;
    
            align-items: center;
            cursor: pointer;
    
            & div {
                padding-left: 20px;
    
                & svg {
                    width: 20px;
                    & path {
                        fill : rgb(148, 1, 1) ; 
                    }
                }
            }
    
            & p {
                width: 200px;
                font-weight: 700;
                opacity: 1;
                color : rgb(148, 1, 1) ; 
                cursor: pointer;
            }
    
    
        }

    }


}
