


.clients-manager {

    & label {
        @include labelStyle()
    }

    & input {
        @include inputStyle()
    }

    & .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;


    }

    & .categorizer-manager__container > div {
        width: 100%;
        height: 100%;
        min-height: 180px;

        & > div {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    & .main-button {
        margin-top: 20px;
    }
    
}