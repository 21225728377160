.homepage {
    width : calc(100% - 70px - 60px); 
    height : calc(100% - 50px - 40px); 
    padding: 20px;
    padding-left: 40px;
    margin-top: 50px;
    margin-left: 70px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;

    & .intro {
        & h1 {
            font-size: 20px;
        }

        & p {
            font-size: 14px;
            font-style: italic;
        }
    }

    & .container {
        width: 400px;
        padding: 10px 20px;
        background-color: #ffffff;
        border: 1px solid rgba(128, 128, 128, 0.217);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        -webkit-box-shadow: 4px 4px 14px -4px #d5e0eab9;  
        box-shadow: 4px 4px 14px -4px #d5e0eab9;
        z-index: 1000;

        & h2 {
            color: $color_1;
            font-size: 18px;
        }

        & > div {
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
            margin-left: 20px;

            & > div, button {
                width: calc(80% - 40px);
                height: 35px;
                margin: 5px 0px;
                padding: 0px 20px;
                display: flex;
                border-radius: 50px;
                align-items: center;
                cursor: pointer;

                & a {
                    text-decoration: none;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;

                    
                }

            }

            & > div {
                font-size: 14px;
                letter-spacing: 0.5px;
                &:nth-child(1) {
                    background-color: $color_2;
                    border: 1.5px solid darken($color_2, 10);
                    color: darken($color_1, 50);
                    font-weight: 500;

                    & a:active {
                        color: darken($color_1, 50);
                    }
                }
                &:nth-child(2) {
                    background-color: $color_1;
                    border: 1.5px solid lighten($color_1, 10);

                    & a {
                        color: white;

                        &:active {
                            color: white;
                        }
                    }

                }
                &:nth-child(3) {
                    background-color: $color_1;
                    border: 1.5px solid lighten($color_1, 10);

                    & a {
                        color: white;

                        &:active {
                            color: white;
                        }
                    }
                }
            }

        }
        
        


    }

    & .illustration {  
        flex: 1 1;
        height: 400px;
        display: flex;
        position: absolute;
        bottom: 50px;
        right: 50px;
        z-index: 100;
    }


}

