.file-deposit {
    display: flex;
    flex-direction: column;
    transition : all 110ms ; 
    z-index: 1000 ; 
    

    & label {
        @include labelStyle()
    }

    & > div:nth-child(2) input, div:nth-child(3) input{
        width: 200px;
        @include inputStyle()
    }

    &--clients, .deposit--prefix, .deposit--seq__number {
        height: 100px;
    }

    &--files {
        width: 400px;
        border: none!important;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-y: scroll;
        min-height: 100px;
        max-height: 400px;
        padding-top: 10px;
        margin: 10px 0px;
        transition : all 110ms ; 

        & .file {
            width: calc(300px - 60px);
            margin: 5px 0px;
            border: 1px solid #DDDEE3;
            padding: 7.5px 30px;
            background-color: white;
            border-radius: 10px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: flex-start ; 
            flex-direction: column;

            & p {
                margin : 0px ; 
                width: 100%;
                overflow: hidden;
            }

            & .mimetype {
                font-style: italic;
                font-weight: 600;
                font-size: 12px;

                &--valid {
                    color : rgb(2, 129, 2)
                }

                &--invalid {
                    color : rgb(172, 3, 3)
                }
            }
            

            & .delete, .preview-icon {
                width: 20px;
                height: 20px;
                position: absolute;
                @include buttonStyle("icons") ; 

            }

            & .preview-icon{
                left: 5px;
                background-color:green ; 

                &:hover {
                    background-color: rgb(1, 92, 1);
                }
            }

            & .delete {
                right: 5px;
                background-color: red;

                &:hover {
                    background-color: rgb(172, 1, 1);
                }
            }
        }

        &--empty {
            height: 200px;
            display: flex;
            justify-content: center;
            align-items: center;

            & svg {
                height: 80%;
            }
        }
    }

    &__preview {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index : 2600 ; 
        background-color: #030905e3;
        display: flex;
        justify-content: center;
        align-items: center;

        &--background {
            width: 100%;
            height: 100%;
            position: fixed;
            z-index  : 1200
        }

        &--container {
            width: 80%;
            height: 90%;
            display: flex;
            z-index: 1500;

            & iframe {
                width: 100%;
                height: 100%;
                border: none;
            }
        }
    }

    &__download {
        display: flex;
        flex-direction: column;
        align-items: center;
        &--container {
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;

            & svg {
                height: 150px;
            }

        }

        & .buttonPrincipal {
            margin-top  : 10px
        }
    }
}