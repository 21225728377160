$font : 'Roboto Slab', serif;
$background_app : #fefefe;


$color_1 : #290049; 
$color_2 : #00ffb4 ; 

$color_gradient : linear-gradient(60deg, rgba(255,134,0,1) 0%, rgba(250,172,15,1) 100%);

@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}